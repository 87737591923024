import React from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bFold">
      <div className="App-Footer">
        <p>
          <strong>Minimum APR:</strong> 2.49%&nbsp;•&nbsp;
          <strong>Maximum APR:</strong> 35.99%&nbsp;•&nbsp;
          <strong>Minimum Loan Repayment Term:</strong> 61 Days&nbsp;•&nbsp;
          <strong>Maximum Loan Repayment Term:</strong> 180 Months&nbsp;•&nbsp;
          <strong>Origination Fee:</strong> 0% - 8%
        </p>
        <p>
          The APR (Annual Percentage Rate) is an annualized interest rate that
          you will be charged on your personal loan. WebLoanPro.com is not a
          lending operation and it does not provide loans, it refers consumers
          to professional and reputable lenders who can provide fast and helpful
          loan assistance. We don't charge fees for this service. WebLoanPro.com
          cannot tell you the APR that your lender charge will be. The APR can
          vary depending on the information that you provide in your initial
          request, and also the information that your lender provides as well.
        </p>
        <p>
          Your lender will tell you all the details on your APR, cash loan
          finance charges, and other terms once you've been redirected to the
          loan agreement while you are in the process of requesting a personal
          loan. We strongly suggest that you closely look over the terms of any
          offer you receive.
        </p>
        <blockquote>
          <p>
            <strong>Representative Personal Loan Example:</strong>
          </p>

          <table className="aprTable">
            <thead>
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Period</th>
                <th scope="col">Monthly</th>
                <th scope="col">Total Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$2000</td>
                <td>12 mo</td>
                <td>$180.52</td>
                <td>$2166.20</td>
              </tr>
              <tr>
                <td>$4000</td>
                <td>24 mo</td>
                <td>$182.74</td>
                <td>$4385.74</td>
              </tr>
              <tr>
                <td>$6000</td>
                <td>36 mo</td>
                <td>$199.29</td>
                <td>$7174.2</td>
              </tr>
            </tbody>
          </table>
        </blockquote>
        <h4>
          <strong>
            Financial Implications (Interest and Finance Charges):
          </strong>
        </h4>
        <p>
          If you are approved for a personal loan, your lender will provide you
          with your exact fees and the interest rate that is associated with
          your loan before you formally agree to the offer. As mentioned,
          WebLoanPro.com is not a lending operation, therefore it is unable to
          notify you of what the exact fees and interest will be on your loan.
          Please know that you are under no obligation to accept any loan term
          that a lender offers.
        </p>
        <ul className="_appLinks_e6ad3">
          <li>
            <a href="/advertising-disclosure">Advertising Disclosure</a>
          </li>
          <li>
            <a href="/do-not-sell-my-personal-information">
              Do Not Sell My Personal Information
            </a>
          </li>
          <li>
            <a href="/terms">Terms</a>
          </li>
          <li>
            <a href="/privacy">Privacy</a>
          </li>
        </ul>
        <ul className="_appLinks_e6ad3">
          <li>© 2022 WebLoanPro</li>
          <li>All Rights Reserved.</li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
