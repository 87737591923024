import React from "react";
import PropTypes from "prop-types";

function QuestionIntro(props) {
  return <div className="questionIntro">{props.content}</div>;
}

QuestionIntro.propTypes = {
  content: PropTypes.string.isRequired,
};

export default QuestionIntro;
