import React, { Component } from "react";
import Footer from "./components/Footer";
import AdvertisingDisclosure from "./components/AdvertisingDisclosure";
import ConsumerPrivacy from "./components/ConsumerPrivacy";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import DoNotSellMPI from "./components/DoNotSellMPI";
import logo from "./assets/img/logo-plp.png";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import QuizApp from "./components/QuizApp";
import { getTrackerAction } from "./components/utils/tracker";

class App extends Component {
  componentDidMount() {
    // redirect back button to homepage
    const currentUrl = window.location.href;
    window.history.replaceState(null, "", "#nb");
    window.history.pushState(null, "", currentUrl);
    window.addEventListener("popstate", function() {
      if (this.window.location.hash === "#nb") {
        window.history.replaceState(null, "", currentUrl);
        setTimeout(function() {
          window.location.href = "/";
        }, 0);
      }
    });
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <div className="App-header">
            <a href="/">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
          <div className="shadowContainer">
            <Switch>
              <Route path="/quiz/1/:page?" component={QuizApp} />
              {/* <Route path="/quiz">
                <div>
                  {this.state.result ? this.renderResult() : this.renderQuiz()}
                  <FunnelFlux type="view" p="1QV3vBCZ0741" />
                </div>
              </Route> */}
              <Route
                path="/quiz"
                component={() => {
                  window.location.href = getTrackerAction(1);
                  return null;
                }}
              />
              <Route path="/ttq/:page?" component={QuizApp} />
              <Route
                path="/advertising-disclosure"
                component={AdvertisingDisclosure}
              />
              <Route path="/consumer-privacy" component={ConsumerPrivacy} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route
                path="/do-not-sell-my-personal-information"
                component={DoNotSellMPI}
              />
              <Route
                path="/contact"
                component={() => {
                  window.location.href = getTrackerAction(1);
                  return null;
                }}
              />
              <Route path="/404">
                <div className="App-content">
                  <h2>Page Not Found</h2>
                </div>
              </Route>
              <Route
                path="/"
                component={() => {
                  window.location.href = getTrackerAction(1);
                  return null;
                }}
              />
              <Redirect to="/404" />
            </Switch>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
