var quizQuestions = [
  {
    enabled: true,
    questionName: "checking-account",
    questionIntro:
      "TO QUALIFY: A checking account at a U.S. bank is required. If you do not have one, you can open a mobile checking account on the next page.",
    question: "Do you have a Checking Account?",
    answers: [
      {
        type: "Yes",
        content: "Yes",
      },
      {
        type: "No",
        content: "No",
      },
    ],
  },
  {
    enabled: true,
    questionName: "credit-score",
    questionIntro:
      "IMPORTANT: For best lending offers, please answer the following question truthfully:",
    question: "Is your credit score at least 640?",
    answers: [
      {
        type: "Yes",
        content: "Yes",
      },
      {
        type: "No",
        content: "No",
      },
      {
        type: "IDK",
        content: "I Don't Know",
      },
    ],
  },
  {
    enabled: true,
    questionName: "boost-score",
    questionIntro:
      "PRO TIP: You can instantly boost your FICO® Score with Experian Boost™ for FREE.  Boost your score before applying for a loan to potentially improve the offers you receive.",
    question: "Boost Your FICO® Score Now?",
    answers: [
      {
        type: "Yes",
        content: "YES! Boost my score now for FREE!",
      },
      {
        type: "No",
        content: "No thanks, proceed with application",
      },
    ],
  },
  {
    enabled: true,
    questionName: "check-score",
    questionIntro:
      "WAIT!! Knowing your current credit score can potentially save you money with lower interest rates, better loan offers and more. We recommend checking your score at all 3 credit bureaus for FREE.",
    question: "Check your credit score now?",
    answers: [
      {
        type: "Yes",
        content: "YES!",
      },
      {
        type: "No",
        content: "No thanks, proceed with application",
      },
    ],
  },
];

export default quizQuestions;
