import React from 'react';
import PropTypes from "prop-types";
import AnswerOption from "../components/AnswerOption";
import AnswerOptionButton from "../components/AnswerOptionButton";

const Answers = (props) => {
    const { type, answer, answerOptions, questionId, onAnswerSelected } = props;

    function renderAnswerOptions(key) {
        if (type === "button") {
            return (
                <AnswerOptionButton
                  key={key.content}
                  answerContent={key.content}
                  answerType={key.type}
                  answer={answer}
                  questionId={questionId}
                  onAnswerSelected={onAnswerSelected}
                />
              );
        } else {
            return (
                <AnswerOption
                  key={key.content}
                  answerContent={key.content}
                  answerType={key.type}
                  answer={answer}
                  questionId={questionId}
                  onAnswerSelected={onAnswerSelected}
                />
              );      
        }
    }

    return ( <ul className={`${type === "button" ? "answerOptionsButton" : "answerOptions"}`}>{answerOptions.map(renderAnswerOptions)}</ul> );
}

Answers.propTypes = {
    type: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
  };

export default Answers;