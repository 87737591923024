import React from "react";

const Privacy = () => {
  return (
    <div className="App-content">
      <div class="_header_4a3ac">
        <h1>Privacy</h1>
        <div class="_meta_4a3ac">Last Updated: August 19, 2021</div>
      </div>
      <div class="_body_4a3ac">
        <div class="_richText_7afec">
          <p>
            Elite Market Force, LLC (“
            <strong>Elite Market Force</strong>
            ”, “<strong>EMF</strong>
            ”, “<strong>WebLoanPro</strong>
            ”, “<strong>WLP</strong>
            ”, “<strong>we</strong>
            ”, “<strong>our</strong>” or “<strong>us</strong>
            ”) has adopted this “<strong>Privacy Policy</strong>” to describe
            how we collect, use, and share your personal information in
            connection with our websites (the “<strong>Sites</strong>
            ”) and any other online content, tools, or services that we control
            and that link to this Privacy Policy (together with the “Sites”, the
            “<strong>Service</strong>
            ”).
          </p>
          <p>
            We provide important information for California residents{" "}
            <a href="#CA">here</a>.
          </p>
          <p>
            Gramm-Leach-Bliley privacy disclosures are located{" "}
            <a href="/consumer-privacy">here</a>.
          </p>
          <h2>Personal Information We Collect</h2>
          <p>
            <strong>Personal information you provide to us.</strong> Personal
            information you may provide to us when you use the Service or
            otherwise includes:
          </p>
          <ul>
            <li>
              <strong>Contact data</strong>, such as your first and last name,
              email address, street address (including zip code), and phone
              number.
            </li>
            <li>
              <strong>Identity data</strong>, such as your Social Security
              number and date of birth.
            </li>
            <li>
              <strong>Credit history and loan data</strong>, such as your
              estimated credit score, the type and status of your current
              loan(s), payment history, ownership or rental history, and other
              information.
            </li>
            <li>
              <strong>Property data</strong>, such as information about the
              home, car, or other property you are financing or insuring.
            </li>
            <li>
              <strong>Employment data</strong>, such as your employment status,
              occupation, income,, and other information.
            </li>
            <li>
              <strong>Education data</strong>, such as education level and
              degrees you have obtained.
            </li>
            <li>
              <strong>Eligibility data</strong> that may tell us whether you
              qualify for certain financial products, such as your citizenship,
              veteran status, or driving records.
            </li>
            <li>
              <strong>Other information</strong> that we may collect which is
              not specifically listed here, which we will use as described in
              this Privacy Policy or as otherwise disclosed at the time of
              collection.
            </li>
          </ul>
          <p>
            For clarity, Elite Market Force collects personal information from
            you to, in part, provide you with personalized content and
            information related to loan and insurance products, but Elite Market
            Force is not a lender or insurance agent. Any loan, insurance, or
            related product to which you may become a party is solely transacted
            between you and the applicable lender, loan originator, and/or
            insurer, and is not a part of the Service.
          </p>
          <p>
            <strong>Other sources</strong>. We may combine personal information
            we receive from you with personal information we obtain from other
            sources, such as:
          </p>
          <ul>
            <li>
              <strong>Data providers</strong>, such as credit bureaus and
              information services.
            </li>
            <li>
              <strong>Public sources</strong>, such as public social media
              platforms.
            </li>
            <li>
              <strong>Business partners</strong>, such as lenders, loan
              aggregators, and insurance companies.
            </li>
          </ul>
          <p>
            <strong>Automatic Collection</strong>. We, our service providers and
            our third-party partners may automatically log information about
            you, your computer or mobile device, and your activity over time on
            the Service and other online services, including:
          </p>
          <ul>
            <li>
              <strong>Online activity data</strong>, such as the date and time
              of your access, visit, or use of the Service, pages or screens you
              viewed, how long you spent on a page or screen, navigation paths
              between pages or screens, searches you conduct on our site, and
              information about your activity on a page or screen.
            </li>
            <li>
              <strong>Device data</strong>, such as unique device identifier,
              media access control address, network information, hardware model,
              browser type, screen resolution, IP address as well as usage and
              traffic data and information about how the device interacts with
              the Service.
            </li>
          </ul>
          <p>
            Cookies and Similar Technologies. The following cookies and similar
            technologies may be used to facilitate automatic data collection:
          </p>
          <ul>
            <li>
              <strong>Cookies</strong>, which are text files that websites store
              on a visitor's device to uniquely identify the visitor's browser
              or to store information or settings in the browser for the purpose
              of tracking user activity and patterns, helping you navigate
              between pages efficiently, remembering your preferences, and
              generally improving your browsing experience.
            </li>
            <li>
              <strong>Web beacons</strong>, also known as pixel tags or clear
              GIFs, which are typically used to demonstrate that a webpage or
              email was accessed or opened, or that certain content was viewed
              or clicked, typically to compile statistics about usage of
              websites and the success of marketing campaigns. We may use web
              beacons to track your use of Sites or to collect information about
              your interactions with our email messages, such as the links you
              click on and whether you open or forward a message, the date and
              time of these interactions, and the device you use to read emails.
              Web beacons may be placed on the Sites or Service by third parties
              such as a social media platform, or an analytics provider in order
              to support functionality described in this Privacy Policy. We also
              use web beacons to allow ad networks to provide anonymized and
              aggregated auditing, research, and reporting for us and for
              advertisers.
            </li>
          </ul>
          <h2>How We Use Your Personal Information</h2>
          <p>
            We use your personal information for the following purposes and as
            otherwise described in this Privacy Policy or at the time of
            collection.
          </p>
          <p>
            <strong>Service delivery.</strong> We may use your personal
            information to:
          </p>
          <ul>
            <li>provide, deliver, and customize your use of the Service;</li>
            <li>
              connect you with lenders, aggregators, loan originators, and/or
              insurance companies and send you Service-related information;
            </li>
            <li>
              communicate with you about the Service and to respond to your
              inquiries, including for customer support;
            </li>
            <li>
              understand your needs and interests, and personalize your
              experience with the Service and our communications; and
            </li>
            <li>provide support and maintenance for the Service.</li>
          </ul>
          <p>
            <strong>Research and development.</strong> We may use your personal
            information for research and development purposes, including to
            analyze and improve the Service and our business. As part of these
            activities, we may create aggregated, de-identified, or other
            anonymous data from personal information we collect. We make
            personal information into anonymous data by removing information
            that makes the data personally identifiable to you. We may use this
            anonymous data and share it with third parties for our lawful
            business purposes, including to analyze and improve the Service and
            promote our business.
          </p>
          <p>
            <strong>Marketing and advertising.</strong> We and our third party
            advertising partners may collect and use your personal information
            for marketing and advertising purposes:
          </p>
          <ul>
            <li>
              <strong>Direct marketing.</strong> We may send you EMF-related or
              other direct marketing communications as permitted by law. You may
              opt-out of our marketing communications as described in the{" "}
              <a href="#opt-out">Opt-out of marketing communications</a> section
              below.
            </li>
            <li>
              <strong>Interest-based advertising.</strong> We may contract with
              third-party advertising companies and social media companies to
              display ads on the Service and other sites. These companies may
              use cookies and similar technologies to try to tailor the ads you
              see online to your interests based on your activity over time
              across the Service and other sites, or your interaction with our
              emails. These ads are known as "interest-based advertisements."
              You can learn more about your choices for limiting interest-based
              advertising, in the{" "}
              <a href="#your-choices">Advertising choices</a> section below.
            </li>
          </ul>
          <p id="compliance">
            <strong>Compliance and protection.</strong> We may use your personal
            information to:
          </p>
          <ul>
            <li>
              comply with applicable laws, lawful requests, and legal process,
              such as to respond to subpoenas or requests from government
              authorities.
            </li>
            <li>
              protect our, your or others' rights, privacy, safety, or property
              (including by making and defending legal claims);
            </li>
            <li>
              enforce the terms and conditions that govern the Services; and
            </li>
            <li>
              prevent, identify, investigate, and deter fraudulent, harmful,
              unauthorized, unethical, or illegal activity, including
              cyberattacks and identity theft.
            </li>
          </ul>
          <p>
            <strong>For other purposes.</strong> We may also use your personal
            information for other purposes described in this Privacy Policy or
            at the time we collect the information.
          </p>
          <h2>How We Share Your Personal Information</h2>
          <p>
            We may share your personal information with the following parties
            and as otherwise described in this Privacy Policy or at the time of
            collection:
          </p>
          <p>
            <strong>Business partners.</strong> Third party companies that we
            partner with to provide content or services to you or to such
            business partners. For example, we will share your contact and other
            information with a lender, loan originator, and/or insurance company
            if the information you provide in one of our online forms or tools
            matches certain criteria for loan or insurance products offered by
            such lender loan originator, and/or insurance company. PLEASE NOTE:
            Our business partners that interact with you may retain or use your
            information whether or not you use their services, and their privacy
            and information sharing practices are set forth in their respective
            privacy policies. Pursuant to the contracts between EMF and its
            business partners, if you select a business partners through our
            Services, EMF may also receive information about you from that
            business partner including, but not limited to, the amount or
            disposition of your loan or other product. EMF and its business
            partners may exchange information about you, your loan terms, and
            your accounts for the purpose of providing you with information
            about financial services and products.
          </p>
          <p>
            <strong>Advertising partners.</strong> Third party advertising
            companies that collect information about your activity on the
            Service and other online services to help us advertise our services,
            and/or use hashed customer lists that we share with them to deliver
            ads to you and similar users on their platforms.
          </p>
          <p>
            <strong>Business transferees.</strong> Relevant participants in
            business transactions (or potential transactions), such as corporate
            divestiture, merger, consolidation, acquisition, reorganization,
            sale, or other disposition of all or any portion of the business or
            assets of, or equity interests in, EMF or our affiliates (including,
            in connection with a bankruptcy or similar proceedings). In any of
            these cases user information, including personal information, could
            be one of the assets transferred to or acquired or accessed by a
            third party.
          </p>
          <p>
            <strong>Service providers.</strong> Other companies and individuals
            that provide services or perform tasks on our behalf to help us
            operate the Service or our business, such as, for example, customer
            support, hosting, analytics, email delivery, marketing services, and
            database management services.
          </p>
          <p>
            <strong>Professional advisors.</strong> Professional advisors, such
            as lawyers, bankers, auditors, and insurers, where necessary in the
            course of the professional services that they render to us.
          </p>
          <p>
            <strong>Authorities and others.</strong> Law enforcement, government
            authorities, and privacy parties, when we believe in good faith it
            is necessary or appropriate for the{" "}
            <a href="#compliance">compliance and operations</a> purposes
            described above.
          </p>
          <p>
            <strong>For other purposes.</strong> We may also share your personal
            information for other purposes described in this Privacy Policy or
            with your consent.
          </p>
          <h2 id="your-choices">Your Choices</h2>
          <p id="opt-out">
            <strong>Opt-out of marketing communications.</strong> You may opt
            out of marketing-related emails by following the opt-out or
            unsubscribe instructions located at the bottom of the email, by
            emailing us at{" "}
            <a href="mailto:privacy@elitemarketforce.com">
              privacy@elitemarketforce.com
            </a>
            , or by submitting your email address{" "}
            <a href="/unsubscribe">here</a>. You may continue to receive
            service-related and other non-marketing emails. If you receive
            marketing text messages from us, you may opt out of receiving
            further marketing text messages from us by replying STOP to our
            marketing message.
          </p>
          <p>
            <strong>Cookies.</strong> Most browsers let you remove or reject
            cookies. To do this, follow the instructions in your browser
            settings. Many browsers accept cookies by default until you change
            your settings. Please note that if you set your browser to disable
            cookies, the Service may not work properly. For more information
            about cookies, including how to see what cookies have been set on
            your browser and how to manage and delete them, visit{" "}
            <a href="https://www.allaboutcookies.org/">
              www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            <strong>Advertising choices.</strong> Some of our advertising
            partners are members of the Network Advertising Initiative (NAI) and
            are subject to the Self-Regulatory Principles for Online Behavioral
            Advertising published by the Digital Advertising Alliance (DAA). You
            can obtain more information about these companies' information
            collection practices, and opt-out of receiving interest-based
            advertising from participating NAI and DAA members at{" "}
            <a href="http://www.networkadvertising.org/managing/opt_out.asp">
              http://www.networkadvertising.org/managing/opt_out.asp
            </a>{" "}
            and/or the DAA's website at{" "}
            <a href="https://optout.aboutads.info">
              https://optout.aboutads.info
            </a>
            . Mobile app users may opt-out of receiving interest-based
            advertising in mobile apps provided by participating members of the
            Digital Advertising Alliance by installing the AppChoices mobile
            app, available at{" "}
            <a href="https://www.youradchoices.com/appchoices">
              https://www.youradchoices.com/appchoices
            </a>
            . You can also limit collection of your information for
            interest-based ads by blocking third party cookies in your browser
            settings or using privacy plug-ins or ad blocking software that help
            you block third party cookies. In addition, your mobile device
            settings may provide functionality to limit use of the advertising
            ID associated with your mobile device for targeted online
            advertising purposes. If you opt-out of interest-based
            advertisements, you will still see advertisements online but they
            may be less relevant to you.
          </p>
          <p>
            Please note that we also may work with companies that offer their
            own opt-out mechanisms or do not participate in the opt-out
            mechanisms described above. Even after using these opt-out
            mechanisms, you may receive interest-based advertising from other
            companies.
          </p>
          <p>
            <strong>Do Not Track.</strong> Some Internet browsers may be
            configured to send “Do Not Track” signals to the online services
            that you visit. We currently do not respond to “Do Not Track” or
            similar signals. To find out more about “Do Not Track,” please visit{" "}
            <a href="https://allaboutdnt.com">https://allaboutdnt.com</a>.
          </p>
          <p>
            <strong>Declining to provide personal information.</strong> If you
            do not provide information that we need to provide the Service, we
            may not be able to provide you with the Service or certain features.
            We will tell you what information you must provide to receive the
            Service when we request it.
          </p>
          <h2>Children</h2>
          <p>
            The Service is not intended for use by children under 18 years of
            age. If we learn that we have collected personal information from a
            child under 18 without the consent of the child's parent or guardian
            as required by law, we will delete it.
          </p>
          <h2>Other Sites and Services</h2>
          <p>
            The Service may contain links to websites and other online services
            operated by third parties. These links are not an endorsement of, or
            representation that we are affiliated with, any third party. In
            addition, our content may be included on web pages or other online
            services that are not associated with us. We do not control websites
            or other online services operated by third parties, and we are not
            responsible for their actions. Other websites and online services
            follow different rules regarding the collection, use and sharing of
            your personal information. We encourage you to read the privacy
            policies of the other websites and online services you use.
          </p>
          <h2>Security</h2>
          <p>
            We employ a number of technical, organizational, and physical
            safeguards designed to protect the personal information we collect.
            Nevertheless, security risk is inherent in all internet and
            information technologies and we cannot guarantee the security of
            your personal information.
          </p>
          <h2>International Data Transfer</h2>
          <p>
            We are headquartered in the United States and may use service
            providers in other countries. Your personal information may be
            transferred to certain places within United States or to other
            locations where privacy laws may not be as protective as those in
            your state, province, or country.
          </p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy at any time, in whole or in part.
            When we make material changes to this Privacy Policy, we will notify
            you by updating the effective date of this Privacy Policy and
            posting the modified Privacy Policy. We may also provide
            notification of changes via email if we have your email address,
            through the Service or in another manner that we believe is
            reasonably likely to reach you. Any modifications to this Privacy
            Policy will be effective when posted (or as otherwise indicated at
            the time of posting). In all cases, your continued use of the
            Service after the posting of any modified Privacy Policy indicates
            your acceptance of the modified Privacy Policy.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our
            information handling practices, please contact us by email at{" "}
            <a href="mailto:privacy@elitemarketforce.com">
              privacy@elitemarketforce.com
            </a>{" "}
            or postal mail at the below address:
          </p>
          <p>
            Elite Market Force, LLC
            <br />
            Attn: Privacy
            <br />
            109 E 17th Street #25
            <br />
            Cheyenne, WY 82001
          </p>
          <h2 id="CA">Important Information for California Residences</h2>
          <p>
            This section applies only to California residents. It describes how
            we collect, use and share Personal Information of California
            residents in operating our business, and their rights with respect
            to that Personal Information. For purposes of this section, “
            <strong>Personal Information</strong>” has the meaning given in the
            California Consumer Privacy Act of 2018 (“CCPA”) but does not
            include information exempted from the scope of the CCPA.
          </p>
          <p>
            <strong>Your California privacy rights.</strong> As a California
            resident, you have the rights listed below. These rights, however,
            are not absolute, and in certain cases we may decline your request
            as permitted by law.
          </p>
          <ul>
            <li>
              <strong>Information.</strong> You can request the following
              information about how we have collected and used your Personal
              Information during the past 12 months:
              <ul>
                <li>
                  The categories of Personal Information that we have collected.
                </li>
                <li>
                  The categories of sources from which we collected Personal
                  Information.
                </li>
                <li>
                  The business or commercial purpose for collecting and/or
                  selling Personal Information.
                </li>
                <li>
                  The categories of third parties with whom we share Personal
                  Information.
                </li>
                <li>
                  Whether we have disclosed your Personal Information for a
                  business purpose, and if so, the categories of Personal
                  Information received by each category of third party
                  recipient.
                </li>
                <li>
                  Whether we've sold your Personal Information and, if so, the
                  categories of Personal Information received by each category
                  of third party recipient.
                </li>
              </ul>
            </li>
            <li>
              <strong>Access.</strong> You can request a copy of the Personal
              Information that we have collected about you during the past 12
              months.
            </li>
            <li>
              <strong>Deletion.</strong> You can ask us to delete the Personal
              Information that we have collected from you.
            </li>
            <li>
              <strong>Opt-out of sales.</strong> If we sell your Personal
              Information, you can opt-out.
            </li>
            <li>
              <strong>Nondiscrimination.</strong> You are entitled to exercise
              the rights described above free from discrimination in the form of
              legally prohibited increases in the price or decreases in the
              quality of our Service.
            </li>
          </ul>
          <p>
            <strong>How to exercise your rights.</strong> You may exercise your
            California privacy rights described above as follows:
          </p>
          <ul>
            <li>
              <strong>Right to information, access, and deletion.</strong> You
              can request to exercise your information, access, and deletion
              rights by:
              <ul>
                <li>
                  emailing{" "}
                  <a href="mailto:ccpa@elitemarketforce.com">
                    ccpa@elitemarketforce.com
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Right to opt-out of the “sale” of your Personal Information.
              </strong>{" "}
              Like many companies online, we use services that help deliver
              interest-based ads to you. Our use of some of these services may
              be classified under the CCPA as a “sale” of your Personal
              Information to the companies that provide the services because
              they collect information from our users (e.g., device data and
              online activity data) to help them serve ads more likely to
              interest you. You can opt-out of this “sale” of your personal
              information here:{" "}
              <a href="/do-not-sell-my-personal-information">
                Do Not Sell My Personal Information
              </a>
            </li>
          </ul>
          <p>
            We reserve the right to confirm your California residence to process
            your requests and will need to confirm your identity to process your
            requests to exercise your information, access, or deletion rights.
            As part of this process, government identification may be required.
            Consistent with California law, you may designate an authorized
            agent to make a request on your behalf. In order to designate an
            authorized agent to make a request on your behalf, you must provide
            a valid power of attorney, the requester's valid government-issued
            identification, and the authorized agent's valid government issued
            identification. We cannot process your request if you do not provide
            us with sufficient detail to allow us to understand and respond to
            it.
          </p>
          <p>
            <strong>
              Personal information that we collect, use and share.
            </strong>
          </p>
          <p>
            The chart below summarizes how we collect, use and share Personal
            Information by reference to the categories specified in the CCPA,
            and describes our practices during the 12 months preceding the
            effective date of this Privacy Policy.
          </p>
          <p>
            Categories in the chart refer to the categories described above in
            the general section of this Privacy Policy.
          </p>
          <div class="responsiveTable">
            <table>
              <tbody>
                <tr>
                  <th>
                    Category of personal information (PI) (definitions are
                    available{" "}
                    <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CIV&amp;division=3.&amp;title=1.81.5.&amp;part=4.&amp;chapter=&amp;article=">
                      here
                    </a>
                    )
                  </th>
                  <th>PI we collect</th>
                  <th>Source of PI</th>
                  <th>Business / commercial purpose for collection</th>
                  <th>
                    Categories of third parties to whom we “disclose” PI for a
                    business purpose
                  </th>
                  <th>
                    Categories of third parties to whom we “sell” PI (as defined
                    by the CCPA)
                  </th>
                </tr>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    <ul>
                      <li>Contact data</li>
                      <li>Identity data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Data providers</li>
                      <li>Public sources</li>
                      <li>Business partners</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Advertising partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Advertising partners (to facilitate online advertising)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>
                    <ul>
                      <li>Credit history and loan data</li>
                      <li>Property data</li>
                      <li>Online activity data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Data providers</li>
                      <li>Public sources</li>
                      <li>Business partners</li>
                      <li>Automatic collection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Advertising partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Advertising partners (online activity data shared to
                        facilitate online advertising)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Financial Information</td>
                  <td>
                    <ul>
                      <li>Credit history and loan data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Data providers</li>
                      <li>Business partners</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing</li>
                      <li>Compliance and Operations</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>None</td>
                </tr>
                <tr>
                  <td>Online Identifiers</td>
                  <td>
                    <ul>
                      <li>Contact data</li>
                      <li>Device data</li>
                      <li>Online activity data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Business partners</li>
                      <li>Automatic collection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Advertising partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Advertising partners (to facilitate online advertising)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Internet or Network Information</td>
                  <td>
                    <ul>
                      <li>Device data</li>
                      <li>Online activity data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Automatic collection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Advertising partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Advertising partners (to facilitate online advertising)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Inferences</td>
                  <td>
                    May be derived from you:
                    <ul>
                      <li>Credit history and loan data</li>
                      <li>Property data</li>
                      <li>Employment data</li>
                      <li>Education data</li>
                      <li>Eligibility data</li>
                      <li>Device data</li>
                      <li>Online activity data</li>
                    </ul>
                  </td>
                  <td>N/A</td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Advertising partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>None</td>
                </tr>
                <tr>
                  <td>Professional or Employment Information</td>
                  <td>
                    <ul>
                      <li>Employment data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Data providers</li>
                      <li>Public sources</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>None</td>
                </tr>
                <tr>
                  <td>Protected Classification Characteristics</td>
                  <td>
                    <ul>
                      <li>Eligibility data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>None</td>
                </tr>
                <tr>
                  <td>Education Information</td>
                  <td>
                    <ul>
                      <li>Education data</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>You</li>
                      <li>Data providers</li>
                      <li>Public sources</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Service delivery</li>
                      <li>Research and development</li>
                      <li>Marketing and advertising</li>
                      <li>Compliance and protection</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Business partners</li>
                      <li>Business transferees</li>
                      <li>Service providers</li>
                      <li>Professional advisors</li>
                      <li>Authorities and others</li>
                    </ul>
                  </td>
                  <td>None</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
