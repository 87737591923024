import React from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import Question from "../components/Question";
// import QuestionCount from "../components/QuestionCount";
import QuestionIntro from "./questionIntro";
import Answers from "./Answers";

function Quiz(props) {
  return (
    <CSSTransitionGroup
      className="container"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={400}
      transitionLeaveTimeout={250}
      transitionAppear
      transitionAppearTimeout={250}
    >
      <div key={props.questionId}>
        <QuestionIntro
          counter={props.questionId}
          content={props.questionIntro}
        />
        {/* <QuestionCount counter={props.questionId} total={props.questionTotal} /> */}
        <Question content={props.question} />
        <Answers type={props.answerOptionsType} {...props} />
      </div>
    </CSSTransitionGroup>
  );
}

Quiz.propTypes = {
  answer: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  answerOptionsType: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionIntro: PropTypes.string,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
};

export default Quiz;
