import React from "react";
import PropTypes from "prop-types";

function AnswerOptionButton(props) {
  return (
    <li className="answerOptionButton">
      <button type="button" id={props.answerType} value={props.answerType} onClick={props.onAnswerSelected}>{props.answerContent}</button>
    </li>
  );
}

AnswerOptionButton.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
};

export default AnswerOptionButton;
