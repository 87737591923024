import { Component } from "react";

class FunnelFlux extends Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.async = true;

    if (this.props.type === "view") {
      script.innerHTML = `
lum.event('view', {
    'query': {
        'f': '${this.props.f}',
        // 'n': '{NODE-ID}',
        'p': '${this.props.p}',
        // 'ts': '{TRAFFICSOURCE-ID}',
        // 'c': '{COST}'
    },
    'options': {
        'cookieAllowed': true,
        'urlRewrite': true,
        'timeOnPage': false,
        'resolveTokens': [],
    },
    'onDone': function (response) {
        window.vid = response.resolvedTokens['{visitor}'];
    }
    });`;
    } else {
      script.innerHTML = `
lum.event('conversion', {
    'query': {
        'rev': 'REVENUE',
        'tx': 'TRANSACTION_ID',
        'p': '${this.props.p}',
        'hit': 'HIT_ID',
        'vid': ''
    },
    'onDone': function (response) {}
    });
        `;
    }

    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}

export default FunnelFlux;
