import React, { Component } from "react";
import quizQuestions from "../api/quizQuestions";
import Quiz from "./Quiz";
import Result from "./Result";
import FunnelFlux from "./FunnelFlux";
import { Route, Switch } from "react-router-dom";
import { getTrackerAction } from "./utils/tracker";

class QuizApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      questionId: 0,
      questionName: "",
      questionIntro: "",
      question: "",
      answerOptions: [],
      answer: "",
      answersResults: {},
      result: "",
      vid: "",
    };

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
  }

  componentDidMount() {
    // const shuffledAnswerOptions = quizQuestions.map((question) =>
    //   this.shuffleArray(question.answers)
    // );

    var pageQuestionId = 0;

    if (typeof this.props.match.params.page !== "undefined") {
      if (this.getQuestionId(this.props.match.params.page) !== -1) {
        pageQuestionId = this.getQuestionId(this.props.match.params.page);
      }
    }

    this.setState({
      questionId: pageQuestionId,
      questionName: quizQuestions[pageQuestionId].questionName,
      questionIntro: quizQuestions[pageQuestionId].questionIntro,
      question: quizQuestions[pageQuestionId].question,
      answerOptions: quizQuestions[pageQuestionId].answers, // shuffledAnswerOptions[0],
    });
  }

  componentDidUpdate() {
    // check if back or forward browser buttons were pressed and make sure proper question is loaded
    window.onpopstate = (e) => {
      if (typeof this.props.match.params.page !== "undefined") {
        const pageQuestionId = this.getQuestionId(this.props.match.params.page);
        if (pageQuestionId !== -1 && pageQuestionId !== this.state.questionId) {
          this.setQuestion(pageQuestionId);
        }
      } else {
        this.setQuestion(0);
      }
    };
  }

  ttqTrack(eventName) {
    if (typeof window !== "undefined") {
      if (window.ttq != null) {
        window.ttq.track(eventName);
      }
    }
  }

  handleAnswerSelected(event) {
    this.setUserAnswer(event.currentTarget.value);

    if (this.state.questionName === "checking-account") {
      this.props.history.replace("./checking-account");
      if (event.currentTarget.value === "No") {
        // go to mobile bank offer
        window.location.href = getTrackerAction(4);
      } else {
        // go to credit score question
        this.props.history.push("./credit-score");
        setTimeout(
          () => this.setQuestion(this.getQuestionId("credit-score")),
          300
        );
      }
    } else if (
      this.state.questionName === "credit-score" &&
      event.currentTarget.value === "IDK"
    ) {
      // go to credit score question
      this.props.history.push("./check-score");
      setTimeout(
        () => this.setQuestion(this.getQuestionId("check-score")),
        300
      );
    } else if (this.state.questionName === "boost-score") {
      if (event.currentTarget.value === "Yes") {
        // go to boost score offer
        this.ttqTrack("ClickButton");
        window.location.href = getTrackerAction(2);
      } else {
        if (
          this.state.answersResults[this.getQuestionId("credit-score")] ===
            "Yes" ||
          typeof this.state.answersResults[
            this.getQuestionId("check-score")
          ] !== "undefined"
        ) {
          // user stated they have good credit
          // go to loan offer
          this.ttqTrack("ClickButton");
          window.location.href = getTrackerAction(1);
        } else {
          // user didn't state they have good credit or hasn't seen credit score check question yet
          // show credit score question
          this.props.history.push("./check-score");
          setTimeout(
            () => this.setQuestion(this.getQuestionId("check-score")),
            300
          );
        }
      }
    } else if (this.state.questionName === "check-score") {
      if (event.currentTarget.value === "Yes") {
        // go to check score offer
        this.ttqTrack("ClickButton");
        window.location.href = getTrackerAction(3);
      } else {
        if (
          quizQuestions[this.getQuestionId("boost-score")].enabled &&
          typeof this.state.answersResults[
            this.getQuestionId("boost-score")
          ] === "undefined"
        ) {
          // go to boost score question
          this.props.history.push("./boost-score");
          setTimeout(
            () => this.setQuestion(this.getQuestionId("boost-score")),
            300
          );
        } else {
          if (
            this.state.answersResults[this.getQuestionId("credit-score")] ===
            "Yes"
          ) {
            // go to loan offer
            this.ttqTrack("ClickButton");
            window.location.href = getTrackerAction(1);
          } else {
            // go to bad credit offers
            this.ttqTrack("ClickButton");
            window.location.href = getTrackerAction(5);
          }
        }
      }
    } else if (this.state.questionId < quizQuestions.length - 1) {
      console.log("id:", this.state.questionId);
      // default behaviour to show next question or results
      var nextQuestionId = this.state.questionId + 1;
      while (!quizQuestions[nextQuestionId].enabled) {
        nextQuestionId++;
        if (typeof quizQuestions[nextQuestionId] === "undefined") {
          // go to loan offer
          window.location.href = getTrackerAction(1);
        }
      }
      this.props.history.push(
        "./" + quizQuestions[nextQuestionId].questionName
      );
      setTimeout(() => this.setQuestion(nextQuestionId), 300);
    } else {
      // go to loan offer
      window.location.href = getTrackerAction(1);
      // setTimeout(() => this.setResults(this.getResults()), 300);
    }
  }

  setUserAnswer(answer) {
    this.setState((state, props) => ({
      answersResults: {
        ...state.answersResults,
        [state.questionId]: answer,
      },
      answer: answer,
    }));
  }

  setQuestion(id) {
    const counter = this.state.counter + 1;
    const questionId = id;

    this.setState({
      counter: counter,
      questionId: questionId,
      questionName: quizQuestions[questionId].questionName,
      questionIntro: quizQuestions[questionId].questionIntro,
      question: quizQuestions[questionId].question,
      answerOptions: quizQuestions[questionId].answers,
      answer: "",
    });
  }

  getQuestionId(name) {
    const id = quizQuestions.findIndex((q) => q.questionName === name);

    return id;
  }

  getResults() {
    const answersCount = this.state.answersResults;
    const answersCountKeys = Object.keys(answersCount);
    const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    return answersCountKeys.filter(
      (key) => answersCount[key] === maxAnswerCount
    );
  }

  setResults(result) {
    if (result.length === 1) {
      this.setState({ result: result[0] });
    } else {
      this.setState({ result: "Undetermined" });
    }
  }

  /* answerOptionsType choices: button or radio */
  renderQuiz(answerOptionsType = "radio") {
    return (
      <Quiz
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        answerOptionsType={answerOptionsType}
        questionId={this.state.questionId}
        questionIntro={this.state.questionIntro}
        question={this.state.question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
      />
    );
  }

  renderResult() {
    return <Result quizResult={this.state.result} />;
  }

  render() {
    return (
      <div className="App-content">
        <h3 className="headline">Get up to $50K as low as 2.49% APR!</h3>
        <Switch>
          <Route path="/quiz/1/:page?">
            {this.state.result ? this.renderResult() : this.renderQuiz()}
            <FunnelFlux type="view" f="1QV7VBJIJa6F" p="1RQuruGrCynU" />
          </Route>
          <Route path="/ttq/:page?">
            {this.state.result
              ? this.renderResult()
              : this.renderQuiz("button")}
            <FunnelFlux type="view" f="1QV7VBJIJa6F" p="1TVjJrkYMHPG" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default QuizApp;
